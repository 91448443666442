import React from 'react'
import "../styles/NavBar.scss";

function Navlist() {
    return (
        <ul className="nav-ham">
            <li className="nav-item ham ">
                <a className="nav-link " aria-current="page" href="/Services">Services </a>
            </li>

            <li className="nav-item ham">
                <a className="nav-link" href="/Aboutus">About Us</a>
            </li>

            <li className="nav-item ham">
                <a className="nav-link " href="/Contactus" >Contact</a>
            </li>

            <li className="nav-item ham">
                <a className="nav-link " href="/Login" >Log In</a>
            </li>
        </ul>
    )
}

export default Navlist